const baseURL=window.api_end;
const currentURL=window.current_url;


//const currentURL="http://localhost:3001/";
//const baseURL='http://127.0.0.1:8000';
const deliveryApiKey=window.deliveryApiKey;
const deliveryURL=window.deliveryURL;
const defaultHeader= {
    'content-type':'application/json',
    };
const formHeader= {
    'content-type': 'multipart/form-data'
};
const timeZoneDifference={
    'Asia/Calcutta':5.30,
    'Canada/Atlantic':-4,
    'Canada/Central': -6,
    'Canada/Eastern':-5,
    'Canada/Mountain':-7,
    'Canada/Newfoundland':-3.30,
     'Canada/Pacific':-8,
    'Canada/Saskatchewan':-6,
    'Canada/Yukon':-7
}

const dishTags=[
    {label:"Veg",value:"Veg"},
    {label:"Non Veg",value:"Non Veg"},
    {label:"Vegan",value:"Vegan"},
    {label:"Gluten-Free",value:"Gluten-Free"},

]
const timeZoneList=[
    'Asia/Calcutta',
    'Canada/Atlantic', 
    'Canada/Central', 
    'Canada/Eastern', 
    'Canada/Mountain', 
    'Canada/Newfoundland',
     'Canada/Pacific',
    'Canada/Saskatchewan',
    'Canada/Yukon'
    ];
//center:{lat:50.445210,lng: -104.618896}
const weekDays=[ "SUNDAY", "MONDAY",
"TUESDAY",
"WEDNESDAY",
"THURSDAY",
"FRIDAY",
"SATURDAY"];
 
    export {baseURL ,currentURL, deliveryURL,defaultHeader,timeZoneList,formHeader,deliveryApiKey}