// @flow
import { Cookies } from 'react-cookie';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }else
    {
        return true;
    }
    console.log('authenticated');
    /*
    const decoded = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        console.warn('access token expired');
        return false;
    } else {
        return true;
    }
    */
};

const setToken=token=>
{
localStorage.setItem('AUTH_TOKEN',token);
};
      /**
 * Logout the user

 */
       const logout=()=> {
        try {
            setSession(null);
           
          
        } catch (error) {}
    }
/**
 * Sets the session
 * @param {*} user
 */
const setSession = user => {
    let cookies = new Cookies();
    if (user) cookies.set('user', user, { path: '/' });
    else 
    {
    cookies.remove('user', { path: '/' });
    localStorage.clear(); //for localStorage
    sessionStorage.clear(); //for sessionStorage
    }
};
const setLoginUser=(responseData)=>
{
    setSession(responseData.user);
    setToken(responseData.access);

}
/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const cookies = new Cookies();
    const user = cookies.get('user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

export { isUserAuthenticated, getLoggedInUser,setLoginUser,logout };
