const itemsPage=10;
class Paginator
{


constructor(totalItemsCount)
{   this.reset(totalItemsCount);
}

reset(totalItemsCount)
{
    this.itemCount=totalItemsCount;
    this.perPage=itemsPage;
    this.startIndex=0;
    this.endIndex=this.perPage;
    this.page=1;
    this.totalPage= Math.ceil(this.itemCount/this.perPage);
}
getNextPage = () => {
    if (this.page!==this.totalPage) {
    var nextPage=this.page+1;
    this.startIndex =(this.page *  this.perPage);
    this.endIndex= nextPage * this.perPage;
    console.log((this.endIndex>this.itemCount),this.itemCount);
    this.endIndex=(this.endIndex>this.itemCount)?this.itemCount:this.endIndex;
    this.page=nextPage;
    }

};
getPrevPage = () => {
    if (this.page!==1) {
    this.page=this.page-1;
    this.startIndex =((this.page-1) *  this.perPage);
    this.endIndex= this.page * this.perPage;
    }

};



}
export default Paginator;