import { fetchData } from "../helpers/apiConnect";
import { defaultHeader, formHeader } from "../helpers/globalConstants";
import { getLoggedInUser } from "../helpers/authUtils";
const MODULE_DETAILS = {
	restaurants: { url: "restaurants", name: "title" },
	restaurant_food_category: { url: "restaurant_food_category", name: "title" },
	restaurant_food_items: { url: "restaurant_food_items", name: "title" },
	days: { url: "days", name: "days" },
	carts: { url: "carts", name: "cart" },
	coupens: { url: "coupens", name: "coupens" },
	extra_payments: { url: "extra_payments", name: "extra_payments" },
	delivery_partners: { url: "delivery_partners", name: "delivery_partners" },
	notifications: { url: "notifications", name: "title" },
	offers: { url: "offers", name: "title" },

	restaurants: { url: "restaurants", name: "title" },
	tax: { url: "tax", name: "title" },
	// 7start
	dish_customization_templates: {
		url: "dish_customization_templates",
		name: "title",
	},
	dish_customization_template_groups: {
		url: "dish_customization_template_groups",
		name: "title",
	},
	get_customers: {
		url: "restaurants/get_customers",
		name: "title",
	},
	create_customer_notifications: {
		url: "restaurants/create_customer_notifications",
		name: "title",
	},
	retrieve_orders: {
		url: "carts/retrieve_orders_restaurant",
		name: "title",
	},
	// 7end
};
class DataService {
	data = [];

	constructor(moduleString) {
		this.moduleString = moduleString;
		this.module = MODULE_DETAILS[this.moduleString];
		this.moduleUrl = this.module.url;
		this.auth = true;
	}
	/*
    1. Create an item with 
    */
	setValue = (valueObj) => {
		localStorage.setItem(valueObj.name, valueObj.value);
	};
	setProfile(profileObj) {
		let profileString = JSON.stringify(profileObj);
		localStorage.setItem("profile", profileString);
	}

	getProfile() {
		let profileString = this.getValue("profile");
		let profileObj = profileString == null ? [] : JSON.parse(profileString);
		return profileObj;
	}

	/*
    1. Create an item with 
    */
	getValue = (name) => {
		return localStorage.getItem(name);
	};
	createData(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};

			options.url = url;

			options.method = "post";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;
			console.log(options);
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response, data) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	getStripeLink(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/get_stripe_connect_link";
			options.method = "post";
			options.header = header;
			options.params = inputParms;

			options.auth = this.auth;
			console.log(options);
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response, data) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	get_stripe_stripe_status(
		inputParms,
		header = defaultHeader,
		url = this.moduleUrl
	) {
		try {
			let options = {};
			options.url = url + "/get_stripe_stripe_status";
			options.method = "post";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;

			console.log(options);
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response, data) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	addMenuItem(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};

			options.url = url + "/add_daily_menu";

			options.method = "post";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;
			console.log(options);
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response, data) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	getDeliveryList(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/get_dp_list";
			options.method = "post";
			options.header = header;
			options.auth = true;
			options.params = inputParms;
			console.log(options);

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	getOrderedCarts(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/get_cart_list";
			options.method = "post";
			options.header = header;
			options.auth = true;
			options.params = inputParms;
			console.log(options);

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	refreshOnlinePayment(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/refresh_online_payment";
			options.method = "post";
			options.header = header;
			options.auth = true;
			options.params = inputParms;
			console.log(options);

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	getCartReports(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/get_cart_reports";
			options.method = "post";
			options.header = header;
			options.auth = true;
			options.params = inputParms;
			console.log(options);

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	getSummaryReports(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/get_summary_reports";
			options.method = "post";
			options.header = header;
			options.auth = true;
			options.params = inputParms;
			console.log(options);

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	deleteSubuser(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};

			options.url = url + "/delete_subuser";

			options.method = "post";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;
			console.log(options);
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response, data) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	updateSubuser(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};

			options.url = url + "/update_subuser";

			options.method = "post";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;
			console.log(options);
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response, data) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	createSubUser(inputParms) {
		return this.createData(
			inputParms,
			formHeader,
			`${this.moduleUrl}/create_subuser`
		);
	}
	createDataFromForm(inputParms, header = formHeader, url = this.moduleUrl) {
		return this.createData(inputParms, header, url);
	}
	createSubRestaurant(inputParms) {
		return this.createData(
			inputParms,
			formHeader,
			`${this.moduleUrl}/create_subrestaurent`
		);
	}
	updateDataFromForm(inputParms, id, header = formHeader, url = this.moduleUrl) {
		return this.updateData(inputParms, id, header, url);
	}
	updateData(inputParms, id, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/" + id;
			options.method = "put";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;
			console.log(options);
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response, data) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	getAbstractData(inputParams) {
		this.getData(inputParams).then((response) => {
			let abstractData = response.data.data.map((obj) => ({
				label: obj[this.module.name],
				value: obj.id,
			}));
			return abstractData;
		});
	}

	/*
    1. Create an item with 
    */

	uploadExcel(inputParms, header = formHeader) {
		try {
			let options = {};
			options.url = this.moduleUrl + "/upload_excel";
			options.method = "post";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;
			console.log(options);
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	/*
    2. Get items 
    */
	getSubRestaurants(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/get_sub_restaurants";
			options.method = "post";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;
			console.log(options);

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	/*
    2. Get items 
    */
	getSubUsers(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/get_sub_users";
			options.method = "post";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;
			console.log(options);

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	/*
    2. Get items 
    */
	getData(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url;
			options.method = "get";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;
			console.log(options);

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	getMyProfile(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url + "/get_my_restaurant";
			options.method = "post";
			options.header = header;
			options.auth = true;
			options.params = inputParms;
			console.log(options);

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}

	getSelectedRestaurant() {
		let user = getLoggedInUser();
		console.log("selectedUser", user);
		let selectedRestaurant = this.getValue("selected_restaurant" + user["id"]);

		selectedRestaurant = selectedRestaurant == null ? 0 : selectedRestaurant;
		console.log("selectedRestaurant", selectedRestaurant);

		return selectedRestaurant;
	}

	setSelectedRestaurant(value) {
		let user = getLoggedInUser();

		let key = "selected_restaurant" + user["id"];

		this.setValue({
			name: key,
			value: value,
		});
	}

	/*
    2. Get items 
    */
	findData(inputParms, header = defaultHeader, url = this.moduleUrl) {
		try {
			let options = {};
			options.url = url;
			options.method = "post";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;
			console.log(options);

			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	/*
    2. Get items 
    */
	getIndividualData(
		id,
		inputParms,
		header = defaultHeader,
		url = this.moduleUrl
	) {
		try {
			let options = {};
			options.url = url + "/" + id;
			options.method = "get";
			options.header = header;
			options.auth = this.auth;
			options.params = inputParms;
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
	/*
    2. Get items 
    */
	deleteData(inputParms, header = defaultHeader) {
		try {
			let options = {};
			options.url = this.moduleUrl + "/" + inputParms["id"];
			options.method = "delete";
			options.header = header;
			options.auth = this.auth;
			options.params = {};
			return new Promise((resolve, reject) => {
				// wrap in timeout to simulate server api call

				fetchData(options).then(
					(response) => {
						resolve(response); // Success!
					},
					(error) => {
						console.log("reason", error); // Error!
						reject("Server error");
					}
				);
			});
		} catch (error) {
			alert(error);
		}
	}
}

export default DataService;
