import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";

import {
	Row,
	Col,
	Container,
	Badge,
	Form,
	InputGroup,
	Button,
	Tab,
	Nav,
	ButtonToolbar,
	ToggleButton,
	ToggleButtonGroup,
	Image,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import ItemsCarousel from "./common/ItemsCarousel";
import ChooseAddressCard from "./common/ChooseAddressCard";
import CheckoutItem from "./common/CheckoutItem";
import MutilUpload from "./common/MultipleImageUploadComponent";
import SimpleReactValidator from "simple-react-validator";
import FileUploader from "./FileUploader";
import { Multiselect } from "multiselect-react-dropdown";
import SearchLocationInput from "../components/location/SearchLocationInput";
import AddAddressModal from "./modals/AddAddressModal";
import FontAwesome from "./common/FontAwesome";
import Icofont from "react-icofont";
import Map from "../components/location/map";
import DataService from "../services/DataService";
import {
	alertCreation,
	alertError,
	confirmAction,
	confirmDeleteAction,
} from "./Alerts";
import TimePicker from "react-bootstrap-time-picker";
import { timeZoneList } from "../helpers/globalConstants";
import {
	timeConvertSeconds,
	toFormattedDate24,
	defaultStartTime,
	serverToLocalTimeDic,
} from "../services/DataFormatHandler";
import { GoogleMap, Polygon, Marker } from "@react-google-maps/api";
import DrawDeliveryZoneModal from "./modals/DrawDeliveryZoneModal";
const time_step = 1;
const KeyCodes = {
	comma: 188,
	enter: 13,
};
//	import { WithContext as ReactTags } from 'react-tag-input';

//center:{lat:50.445210,lng: -104.618896}
const weekDays = [
	"SUNDAY",
	"MONDAY",
	"TUESDAY",
	"WEDNESDAY",
	"THURSDAY",
	"FRIDAY",
	"SATURDAY",
];
const delimiters = [KeyCodes.comma, KeyCodes.enter];
const timeCall = {
	SUNDAY: 1,
	MONDAY: 2,
	TUESDAY: 3,
	WEDNESDAY: 4,
	THURSDAY: 5,
	FRIDAY: 6,
	SATURDAY: 7,
};
const getDayIndex = (key) => {
	return timeCall[key];
};

// 7start
const jsonParse = (string) => {
	if (typeof string !== "string") {
		return null;
	}
	try {
		return JSON.parse(string);
	} catch (error) {
		return null;
	}
};
// 7end
class ProfileEdit extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
		this.dataHandler = new DataService("restaurants");
		this.selectedRestaurant = this.dataHandler.getSelectedRestaurant();

		this.logo = "";
		this.banner = "";
		this.default_food_item_image = "";
		this.addedGalleryImages = [];
		this.galleryImages = [];
		this.state = {
			dailyShedules: [],
			currentData: {},
			showAddressModal: false,
			galleryImages: [],
			showHideDelivery: false,
			removingImages: [],
			opening_time: 0,
			closing_time: 0,
			tags: [],
			profile: [],
			daysData: [],
			address: "",
			selectedRestaurant: this.dataHandler.getSelectedRestaurant(),

			taxTimePickers: [],
			createForm: { tax_enabled: true, address: "" },
			center: { lat: 9.5137, lng: 76.569092 },
			// 7start
			showDeliveryZoneModal: false,
			deliveryZones: [],
			currentDrawZoneIndex: null,
			polygonPath: [
				{ lat: 45.49790047659501, lng: -73.56996506249106 },
				{ lat: 45.48629772169646, lng: -73.59324896846782 },
				{ lat: 45.50010991774973, lng: -73.59016368652368 },
			],
			// 7end
		};

		this.dayHandler = new DataService("days");

		this.taxData = [];

		this.hideDelivery = this.hideDelivery.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleAddition = this.handleAddition.bind(this);
		this.handleDrag = this.handleDrag.bind(this);
		this.handleTagClick = this.handleTagClick.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
		this.handleMap = this.handleMap.bind(this);
	}

	componentDidMount() {
		this.reloadTaxData();
		this.reloadDays();
		this.reloadData();

		this.reloadDeliveryPartners();
	}
	/*
	  fetch elements from server and reload data elements
	  */
	reloadDays = () => {
		let inputParams = {};
		this.dayHandler.getData(inputParams).then((response) => {
			let currentData = response.data.data;
			this.setState({ daysData: currentData });
		});
	};

	getStartTime = (keyDay, i) => {
		if (!i) {
			i =
				this.state.dailyShedules.length > 0
					? this.state.dailyShedules.length - 1
					: 0;
		}
		let startTime = "0:0:0";
		let maxTime = 0;
		console.log(this.state.dailyShedules);
		if (this.state.dailyShedules && this.state.dailyShedules.length > 0) {
			this.state.dailyShedules.slice(0, i).map((dayObj) => {
				if (dayObj.day === keyDay && !dayObj["remove"]) {
					if (maxTime < dayObj.closing_time) {
						maxTime = dayObj.closing_time;
					}
				}
			});
		}

		return (startTime = toFormattedDate24(maxTime));
	};

	isTimeLeft = (keyDay, i) => {
		let startTime = "0:0:0";
		let maxTime = 0;
		this.state.dailyShedules.map((dayObj) => {
			if (dayObj.day == keyDay && !dayObj["remove"]) {
				if (maxTime < dayObj.closing_time) {
					maxTime = dayObj.closing_time;
				}
			}
		});

		if (maxTime < 82000) {
			return true;
		} else {
			return false;
		}
	};
	getNextDay() {
		let leftDays = weekDays.map((dayObj, index) => {
			if (this.isTimeLeft(dayObj)) {
				return { day: dayObj };
			}
		});

		return leftDays[0];
	}

	handleMap(addressObject) {
		let inputForm = { ...this.state.createForm };
		inputForm["address"] = addressObject.formatted_address;
		if (addressObject.geometry) {
			let lat = addressObject.geometry.location.lat();
			let lng = addressObject.geometry.location.lng();
			inputForm["lat"] = lat;
			inputForm["lng"] = lng;
		}
		this.setState({ createForm: inputForm });
	}
	handleDelete(i) {
		const { tags } = this.state;
		this.setState({
			tags: tags.filter((tag, index) => index !== i),
		});
	}

	setLocation(centerSelected) {
		let oldCenter = { ...this.state.center };
		this.setState({ center: centerSelected });
	}
	handleAddition(tag) {
		this.setState((state) => ({ tags: [...state.tags, tag] }));
	}

	handleDrag(tag, currPos, newPos) {
		const tags = [...this.state.tags];
		const newTags = tags.slice();

		newTags.splice(currPos, 1);
		newTags.splice(newPos, 0, tag);

		// re-render
		this.setState({ tags: newTags });
	}

	handleTagClick(index) {
		console.log("The tag at index " + index + " was clicked");
	}

	handleTimeZone = (selectedItem) => {
		let value = selectedItem.target.value;

		let inputForm = { ...this.state.createForm };
		inputForm["time_zone"] = value;
		this.setState({ createForm: inputForm });
	};

	createFormHandler = (event) => {
		let key = "";
		let value = "";
		let inputForm = { ...this.state.createForm };

		if (
			(event.target.type == "text") |
				(event.target.type == "number") |
				(event.target.type == "email") ||
			event.target.type == "password" ||
			event.target.type == "textarea"
		) {
			key = event.target.name;
			value = event.target.value;
		} else if (event.target.type == "date") {
			key = event.target.name;
			value = event.target.value;
		} else if (event.target.type == "select") {
			key = event.target.name;
			value = event.target.value;
		} else if (event.target.type == "checkbox") {
			key = event.target.name;
			value = event.target.checked;
		} else if (event.target.type == "file") {
			key = event.target.name;
			value = event.target.files[0];
		}
		if (key == "excelFile") {
			this.state.uploadExcelForm[key] = value;
		} else {
			if (key) {
				inputForm[key] = value;
				console.log(inputForm);
				this.setState({ createForm: inputForm });
			}
		}
	};

	handleFiles = (fileList) => {
		console.log(fileList);
		if (fileList) {
			this.galleryImages = fileList;
		}
	};

	onSelectTax = (selectedItem) => {
		let inputForm = { ...this.state.createForm };
		let tax_id = selectedItem.target.value;
		inputForm["appliicable_tax"] = tax_id;
		this.setState({ createForm: inputForm });
	};

	handleDeliveryPartner = (selectedItem) => {
		let inputForm = { ...this.state.createForm };
		let dp_id = selectedItem.target.value;

		inputForm["delivery_partner"] = dp_id;

		this.setState({ createForm: inputForm });
	};
	/*
	fetch elements from server and reload data elements
	*/
	reloadTaxData = () => {
		let inputParams = {};
		new DataService("tax").getData(inputParams).then((response) => {
			let abstractData = [];
			console.log(response.data);
			if (response.data) {
				abstractData = response.data.map((obj) => ({
					name: obj["title"],
					id: obj.id,
				}));
			}
			this.setState({ taxOptions: abstractData });
		});
	};

	reloadDeliveryPartners = () => {
		let inputParams = {};
		new DataService("delivery_partners")
			.getDeliveryList(inputParams)
			.then((response) => {
				if (response.data.data) {
					console.log("del");
					console.log(response.data.data);
					this.setState({ delOptions: response.data.data });
				}
			});
	};
	/*
		fetch elements from server and reload data elements
		*/
	reloadCategoryData = () => {
		let inputParams = {};
		new DataService("restaurant_food_category")
			.getData(inputParams)
			.then((response) => {
				let abstractData = response.data.data.map((obj) => ({
					name: obj["title"],
					id: obj.id,
				}));
				this.setState({ taxOptions: abstractData });
			});
	};

	updateProfile = () => {
		if (this.validator.allValid() == true) {
			let inputParams = this.state.createForm;
			console.log(inputParams);

			if (
				!inputParams["order_notification_email"] ||
				inputParams["order_notification_email"].length == 0
			) {
				inputParams["order_notification_email"] = inputParams["email"];
			}
			if (
				!inputParams["store_email"] ||
				inputParams["store_email"].length == 0
			) {
				inputParams["store_email"] = inputParams["email"];
			}
			let formData = new FormData(); //formdata object
			Object.keys(inputParams).forEach(function (key) {
				formData.append(key, inputParams[key]); //append the values with key, value pair
			});
			for (let i = 0; i < this.galleryImages.length; i++) {
				formData.append("gallery_images", this.galleryImages[i]);
			}
			if (this.state.removingImages.length > 0) {
				let removingImagesString = JSON.stringify(
					this.state.removingImages
				);
				formData.append("removing_images", removingImagesString);
			}

			if (this.state.dailyShedules) {
				let dailyShedulesObj = [...this.state.dailyShedules];
				//dailyShedulesObj=convertTimeDicToUTC(dailyShedulesObj);
				let time_sheduleString = JSON.stringify(dailyShedulesObj);
				formData.append("time_shedule", time_sheduleString);
			}

			// 7start
			if (this.state.deliveryZones) {
				let deliveryZonesObj = [...this.state.deliveryZones];
				let deliveryZonesString = JSON.stringify(deliveryZonesObj);
				formData.append("delivery_zones", deliveryZonesString);
			}
			// 7end

			this.dataHandler
				.updateDataFromForm(formData, this.state.rest_id)
				.then((response) => {
					if (response.data["status"] === "Sucess") {
						alertCreation("Restaurant");
					} else {
						alertError(response.data["message"]);
					}
				})
				.catch((err) => alert(err));
		} else {
			this.validator.showMessages();
		}
	};

	compareDicValues = (day1, day2) => {
		let compairValue = 0;
		if (getDayIndex(day1["day"]) == getDayIndex(day2["day"])) {
			compairValue =
				getDayIndex(day1["opening_time"]) >
				getDayIndex(day2["opening_time"])
					? 1
					: -1;
		} else {
			compairValue =
				getDayIndex(day1["day"]) > getDayIndex(day2["day"]) ? 1 : -1;
		}
		return compairValue;
	};

	deleteImage = (imageId) => {
		let currentAddedImages = this.state.galleryImages.filter((imgObj) => {
			return imgObj.id != imageId;
		});

		this.setState({ galleryImages: currentAddedImages });
		let removingImages = [...this.state.removingImages];
		removingImages.push(imageId);
		this.setState({ removingImages: removingImages });
	};

	/*
		fetch elements from server and reload data elements
		*/
	reloadData = () => {
		let inputParams = {};
		console.log("currentSelection", this.state.selectedRestaurant);
		if (this.state.selectedRestaurant != 0) {
			inputParams = { rest_id: this.state.selectedRestaurant };
		}
		this.dataHandler.getMyProfile(inputParams).then((response) => {
			let currentData = response.data.data;
			this.dataHandler.setProfile(currentData);
			let restaurantName = currentData.restaurant_name;
			let restaurantAddress = currentData.address;
			let logo = currentData.logo;
			this.setData(currentData);
		});
	};

	setData = (currentData) => {
		let restaurantName = currentData.restaurant_name;
		let restaurantAddress = currentData.address;
		let logo = currentData.logo;
		this.logo = logo;
		this.banner = currentData.banner;
		this.default_food_item_image = currentData.default_food_item_image;
		console.log(currentData);
		let inputParams = {};

		inputParams["phone"] = currentData.phone;
		inputParams["restaurant_name"] = currentData.restaurant_name;
		inputParams["tab_title"] = currentData.tab_title;
		if (
			currentData.delivery_partners &&
			currentData.delivery_partners.length > 0
		) {
			inputParams["delivery_partner"] = currentData.delivery_partners[0];
		}
		inputParams["alternative_phone"] = currentData.alternative_phone;
		inputParams["address"] = currentData.address;
		inputParams["minim_delevery"] = currentData.minim_delevery;
		inputParams["maxim_delevery"] = currentData.maxim_delevery;
		inputParams["stripe_key"] = currentData.stripe_key;
		inputParams["stripe_secret_key"] = currentData.stripe_secret_key;
		inputParams["is_multiple_franchaise_enabled"] =
			currentData.is_multiple_franchaise_enabled;

		inputParams["online_payment_mode"] = currentData.online_payment_mode;

		inputParams["meta_tags"] = currentData.meta_tags;
		inputParams["description"] = currentData.description;

		inputParams["time_zone"] = currentData.time_zone;
		inputParams["max_delevery_charge"] = currentData.max_delevery_charge;

		inputParams["delevery_charge"] = currentData.delevery_charge;
		inputParams["delevery_free"] = currentData.delevery_free;
		inputParams["store_email"] = currentData.store_email;
		inputParams["order_notification_email"] =
			currentData.order_notification_email;
		inputParams["avg_preparation_time"] = currentData.avg_preparation_time;
		inputParams["preparation_time_for_delivery"] =
			currentData.preparation_time_for_delivery;
		inputParams["delivery_team_id"] = currentData.delivery_team_id;
		inputParams["takeout_response_time"] = currentData.takeout_response_time;
		inputParams["gst_number"] = currentData.gst_number;
		inputParams["pst_number"] = currentData.pst_number;

		inputParams["tax_enabled"] = currentData.tax_enabled;
		inputParams["close_restaurent"] = currentData.close_restaurent;
		inputParams["enable_pop_up"] = currentData.enable_pop_up;
		inputParams["message_pop_up"] = currentData.message_pop_up;
		inputParams["enable_pay_collection"] = currentData.enable_pay_collection;
		inputParams["store_id"] = currentData.store_id;
		inputParams["checkout_id"] = currentData.checkout_id;
		inputParams["api_token"] = currentData.api_token;
		inputParams["delevery_enabling_charge"] =
			currentData.delevery_enabling_charge;
		// 7start
		inputParams["enable_draw_delivery_zone"] =
			currentData.enable_draw_delivery_zone;
		inputParams["addressLat"] = currentData.lat;
		inputParams["addressLng"] = currentData.lng;
		// 7end

		inputParams["payment_provided_url"] = currentData.payment_provided_url;
		inputParams["payment_app_id"] = currentData.payment_app_id;
		inputParams["hpp_key"] = currentData.hpp_key;
		inputParams["order_email_notification"] =
			currentData.order_email_notification;
		inputParams["disable_pay_at_store"] = currentData.disable_pay_at_store;
		inputParams["disable_pay_on_delivery"] =
			currentData.disable_pay_on_delivery;
		inputParams["enable_auto_delivery_request"] =
			currentData.enable_auto_delivery_request;
		inputParams["disable_takeout"] = currentData.disable_takeout;
		inputParams["disable_delivery"] = currentData.disable_delivery;
		inputParams["delivery_instructions"] = currentData.delivery_instructions;

		inputParams["enable_delevery_free"] = currentData.enable_delevery_free;
		inputParams["enable_takeout_discount"] =
			currentData.enable_takeout_discount;
		inputParams["take_out_discount_minim_value"] =
			currentData.take_out_discount_minim_value;
		inputParams["take_out_discount"] = currentData.take_out_discount;

		inputParams["delevery_free"] = currentData.delevery_free;
		inputParams["max_delevery_free"] = currentData.max_delevery_free;
		if (
			inputParams["tax_enabled"] === true &&
			inputParams["appliicable_tax"] != null
		) {
			inputParams["appliicable_tax"] = currentData.appliicable_tax["id"];
		}

		let timeShedule = [];
		if (currentData.available_days && currentData.available_days.length > 0) {
			timeShedule = timeConvertSeconds(currentData.available_days);
		}
		// 7start
		let deliveryZoneDetails = [];
		if (currentData.delivery_zones && currentData.delivery_zones.length > 0) {
			deliveryZoneDetails = currentData.delivery_zones;
		}
		// 7end
		inputParams["enable_separate_delivery_schedule"] =
			currentData.enable_separate_delivery_schedule;
		inputParams["enable_tip_in_tookan_online_orders"] =
			currentData.enable_tip_in_tookan_online_orders;
		console.log("setting staed");
		this.setState({
			createForm: inputParams,
			galleryImages: currentData.images,
			rest_id: currentData.id,
			dailyShedules: timeShedule,
			deliveryZones: deliveryZoneDetails,
			currentData: currentData,
		});
	};

	setDailyShedules(timeShedule) {
		//timeShedule.sort((day1, day2) => this.compareDicValues(day1,day2))
		this.setState({ dailyShedules: timeShedule });
	}

	/*
 
 handles event of product partb select box
 
	 */
	handlePaymentModeChange = (selectedItem) => {
		let paymode = selectedItem.target.value;
		let inputParams = { ...this.state.createForm };
		inputParams["online_payment_mode"] = paymode;
		this.setState({ createForm: inputParams });
	};

	handleDayChange = (index, selectedItem) => {
		let day = selectedItem.target.value;

		let dayValue = day;
		let keyName = "day";
		let list = [...this.state.dailyShedules];

		list[index][keyName] = dayValue;

		console.log(list);
		this.setState({ dailyShedules: list });
	};

	dayTimeSheduleChange(index, time, shedule) {
		let list = [...this.state.dailyShedules];
		list[index][shedule] = time;
		if (shedule === "opening_time" && time > list[index]["closing_time"]) {
			// list[index]['closing_time'] = time;
			list[index]["closing_time"] = time;
		} else if (
			shedule === "closing_time" &&
			time < list[index]["opening_time"]
		) {
			list[index]["opening_time"] = time;
		}

		this.setState({ dailyShedules: list });
	}

	//addd Return Methods

	// handle click event of the Remove button
	handleRemoveShedule = (index) => {
		let list = [...this.state.dailyShedules];
		if (list[index]["add"]) {
			list.splice(index, 1);
		} else {
			list[index]["remove"] = true;
		}
		this.setState({ dailyShedules: list });
	};

	// handle click event of the Add button
	handleAddShedule = (params = null, orderMode = 1) => {
		let dayList = [...this.state.dailyShedules];
		let values = {
			day: "SUNDAY",
			opening_time: 0,
			closing_time: 0,
			add: true,
			order_mode: orderMode,
		};
		if (params && params.hasOwnProperty("copy") && params.copy === true) {
			values = params.values;
			values["add"] = true;
			values["order_mode"] = orderMode;
		}
		dayList.push(values);
		this.setState({ dailyShedules: dayList });
	};

	hideAddressModal = () => this.setState({ showAddressModal: false });

	hideDelivery(name) {
		this.setState({ showHideDelivery: !this.state.showHideDelivery });
	}

	getQty = ({ id, quantity }) => {
		//console.log(id);
		//console.log(quantity);
	};

	// 7start
	hideDeliveryZoneModal = () => {
		this.setState({ showDeliveryZoneModal: false });
		this.setState({ currentDrawZoneIndex: null });
	};

	handleAddZone = () => {
		let zoneList = [...this.state.deliveryZones];
		zoneList.push({
			title: "untitled",
			delivery_charge: 0.0,
			is_enabled: false,
			coordinates: null,
			add: true,
			delivery_enabling_order_value: 0.0,
			enable_delivery_free: false,
			delivery_free_min_order_value: 0.0,
		});
		this.setState({ deliveryZones: zoneList });
	};

	handleRemoveZone = (index) => {
		let zoneList = [...this.state.deliveryZones];
		if (zoneList[index]["add"]) {
			zoneList.splice(index, 1);
		} else {
			zoneList[index]["remove"] = true;
		}
		this.setState({ deliveryZones: zoneList });
	};

	onClickDrawZone = (index) => {
		this.setState({ showDeliveryZoneModal: true });
		this.setState({ currentDrawZoneIndex: index });
		let zoneCoordinates = jsonParse(
			this.state.deliveryZones[index].coordinates
		);
		if (zoneCoordinates && zoneCoordinates.length > 2) {
			this.setState({ polygonPath: zoneCoordinates });
		} else {
			let addressLat = Number(this.state.createForm["addressLat"]);
			let addressLng = Number(this.state.createForm["addressLng"]);
			this.setState({
				polygonPath: [
					{ lat: addressLat - 0.001, lng: addressLng },
					{ lat: addressLat + 0.001, lng: addressLng - 0.001 },
					{ lat: addressLat + 0.001, lng: addressLng + 0.001 },
				],
			});
		}
	};

	onUpdateZone = (newPath) => {
		let zoneList = [...this.state.deliveryZones];
		let index = this.state.currentDrawZoneIndex;
		if (index !== null) {
			zoneList[index].coordinates = JSON.stringify(newPath);
			this.setState({ currentDrawZoneIndex: null });
			this.setState({ deliveryZones: zoneList });
		}
		this.setState({ showDeliveryZoneModal: false });
	};

	handleDeliveryZoneChanges = (index, selectedItem) => {
		let targetName = selectedItem.target.name;
		let newValue = selectedItem.target.value;
		let zoneList = [...this.state.deliveryZones];

		if (targetName === "delivery_zone_title") {
			zoneList[index].title = newValue;
		}

		if (targetName === "delivery_zone_charge") {
			zoneList[index].delivery_charge = newValue;
		}

		if (targetName === "enable_delivery_zone") {
			zoneList[index].is_enabled = selectedItem.target.checked;
		}

		if (targetName === "enable_delivery_free") {
			zoneList[index].enable_delivery_free = selectedItem.target.checked;
		}

		if (targetName === "delivery_free_min_order_value") {
			zoneList[index].delivery_free_min_order_value = newValue;
		}

		if (targetName === "delivery_enabling_order_value") {
			zoneList[index].delivery_enabling_order_value = newValue;
		}

		this.setState({ deliveryZones: zoneList });
	};

	// showMaximumZone = () =>{
	// 	if (!this.state.selectedExcludedZone && !this.state.selectedMinimumZone) {
	// 		if (!this.state.showGoogleMap) {
	// 			this.setState({showGoogleMap:true});
	// 			if (!this.state.selectedMaximumZone) {
	// 				this.setState({selectedMaximumZone: true});
	// 				if (this.state.createForm['delivery_maximum_zone'] &&
	// 				    this.state.createForm['delivery_maximum_zone'].length>2) {
	// 					this.setState({polygonPath: jsonParse(this.state.createForm['delivery_maximum_zone'])})
	// 				}
	// 			}
	// 		}
	// 		else {
	// 			this.setState({showGoogleMap: false});
	// 			if (this.state.selectedMaximumZone) {
	// 				this.setState({selectedMaximumZone: false});
	// 			}
	// 		}
	// 	}
	// }

	// showMinimumZone = () =>{
	// 	if (!this.state.selectedExcludedZone && !this.state.selectedMaximumZone) {
	// 		if (!this.state.showGoogleMap) {
	// 			this.setState({showGoogleMap:true});
	// 			if (!this.state.selectedMinimumZone) {
	// 				this.setState({selectedMinimumZone:true});
	// 				if (this.state.createForm['delivery_minimum_zone'] &&
	// 				    this.state.createForm['delivery_minimum_zone'].length>2) {
	// 					this.setState({polygonPath: jsonParse(this.state.createForm['delivery_minimum_zone'])})
	// 				}
	// 			}
	// 		}
	// 		else {
	// 			this.setState({showGoogleMap: false});
	// 			if (this.state.selectedMinimumZone) {
	// 				this.setState({selectedMinimumZone: false});
	// 			}
	// 		}
	// 	}
	// }

	// showExcludedZone = () =>{
	// 	if (!this.state.selectedMaximumZone && !this.state.selectedMinimumZone) {
	// 		if (!this.state.showGoogleMap) {
	// 			this.setState({showGoogleMap:true});
	// 			if (!this.state.selectedExcludedZone) {
	// 				this.setState({selectedExcludedZone: true});
	// 				if (this.state.createForm['delivery_excluded_zone'] &&
	// 				    this.state.createForm['delivery_excluded_zone'].length>2) {
	// 					this.setState({polygonPath: jsonParse(this.state.createForm['delivery_excluded_zone'])})
	// 				}
	// 			}
	// 		}
	// 		else {
	// 			this.setState({showGoogleMap: false});
	// 			if (this.state.selectedExcludedZone) {
	// 				this.setState({selectedExcludedZone: false});
	// 			}
	// 		}
	//     }
	// }

	// updateZonePath = () =>{
	// 	let inputForm = { ...this.state.createForm };
	// 	let strPolygonPath = JSON.stringify(this.state.polygonPath);
	// 	if (this.state.selectedMaximumZone) {
	// 		inputForm['delivery_maximum_zone'] = strPolygonPath;
	// 	}
	// 	if (this.state.selectedExcludedZone) {
	// 		inputForm['delivery_excluded_zone'] = strPolygonPath;
	// 	}
	// 	if (this.state.selectedMinimumZone) {
	// 		inputForm['delivery_minimum_zone'] = strPolygonPath;
	// 	}
	// 	this.setState({ createForm: inputForm });
	// 	this.setState({showGoogleMap: false});
	// 	this.setState({selectedMaximumZone: false});
	// 	this.setState({selectedExcludedZone: false});
	// 	this.setState({selectedMinimumZone:false});
	// }
	// 7end

	render() {
		const { tags } = this.state;

		return (
			<section className="offer-dedicated-body mt-4 mb-4 pt-2 pb-2">
				<AddAddressModal
					show={this.state.showAddressModal}
					onHide={this.hideAddressModal}
				/>

				{/* 7start */}
				<DrawDeliveryZoneModal
					show={this.state.showDeliveryZoneModal}
					onHide={this.hideDeliveryZoneModal}
					polygonPath={this.state.polygonPath}
					onUpdate={this.onUpdateZone}
				/>
				{/* 7end */}

				<Container>
					<Row>
						<Col md={12}>
							<div className="offer-dedicated-body-left">
								<div className="pt-2"></div>

								<span className="hidden">
									{/*this.props.shouldHide ? 'hidden' : ''*/}
									<div className="bg-white rounded shadow-sm p-4 mb-4">
										<h4 className="mb-1">
											Edit Restaurant Profile
										</h4>
										<h6 className="mb-3 text-black-50">
											provide Account Related Info
										</h6>
										<Row>
											<Col md={12}>
												<Form>
													<div className="form-row">
														<Form.Group className="col-md-12">
															<Form.Label>
																Logo
															</Form.Label>
															<div className="osahan-user-media">
																{
																	<Image
																		className="mb-3 rounded-pill shadow-sm mt-1"
																		src={
																			this
																				.logo
																		}
																		alt="resturent"
																	/>
																}
															</div>
															<InputGroup>
																<Form.Control
																	type="file"
																	name="logo"
																	onChange={
																		this
																			.createFormHandler
																	}
																	placeholder="Logo"
																/>
															</InputGroup>
														</Form.Group>

														<Form.Group className="col-md-12">
															<Form.Label>
																Banner
															</Form.Label>
															<div className="osahan-user-media">
																{
																	//  <Image className="mb-3 rounded-pill shadow-sm mt-1" src="/img/1.jpg" alt="resturent"/>
																}
															</div>
															<InputGroup>
																<Form.Control
																	type="file"
																	name="banner"
																	onChange={
																		this
																			.createFormHandler
																	}
																	placeholder="Banner"
																/>
															</InputGroup>
														</Form.Group>

														<Form.Group className="col-md-12">
															<Form.Label>
																Default Dish Image
															</Form.Label>
															<div className="osahan-user-media">
																{this
																	.default_food_item_image && (
																	<Image
																		className="mb-3  shadow-sm mt-1"
																		src={
																			this
																				.default_food_item_image
																		}
																		alt=""
																	/>
																)}
															</div>
															<InputGroup>
																<Form.Control
																	type="file"
																	name="default_food_item_image"
																	onChange={
																		this
																			.createFormHandler
																	}
																	placeholder="Default Dish Image"
																/>
															</InputGroup>
														</Form.Group>

														<Form.Group className="col-md-12">
															<Form.Label>
																Restaurant Name
															</Form.Label>
															{this.validator.message(
																"restaurant_name",
																this.state
																	.createForm[
																	"restaurant_name"
																],
																"required",
																{
																	className:
																		"text-danger",
																}
															)}

															<InputGroup>
																<Form.Control
																	type="text"
																	onChange={
																		this
																			.createFormHandler
																	}
																	value={
																		this.state
																			.createForm[
																			"restaurant_name"
																		]
																	}
																	name="restaurant_name"
																	placeholder="Restaurant Name"
																/>
															</InputGroup>
														</Form.Group>
														{this.state.currentData
															.is_sub_restaurant ==
															false && (
															<Form.Group controlId="formBasicCheckbox">
																<Form.Check
																	type="checkbox"
																	name="is_multiple_franchaise_enabled"
																	checked={
																		this.state
																			.createForm[
																			"is_multiple_franchaise_enabled"
																		]
																	}
																	onClick={
																		this
																			.createFormHandler
																	}
																	label="is multiple franchise enabled"
																/>
															</Form.Group>
														)}

														<Form.Group className="col-md-12">
															<Form.Label>
																Tab title to be
																displayed
															</Form.Label>

															<InputGroup>
																<Form.Control
																	type="text"
																	onChange={
																		this
																			.createFormHandler
																	}
																	value={
																		this.state
																			.createForm[
																			"tab_title"
																		]
																	}
																	name="tab_title"
																	placeholder="Restaurant Tab Title"
																/>
															</InputGroup>
														</Form.Group>
														<Form.Group className="col-md-12">
															<Form.Label>
																Description
															</Form.Label>

															<InputGroup>
																<Form.Control
																	type="textarea"
																	onChange={
																		this
																			.createFormHandler
																	}
																	value={
																		this.state
																			.createForm[
																			"description"
																		]
																	}
																	name="description"
																	placeholder="Resturent description"
																/>
															</InputGroup>
														</Form.Group>

														<Form.Group className="col-md-12">
															<Form.Label>
																Tags
															</Form.Label>
															<Form.Control
																type="text"
																onChange={
																	this
																		.createFormHandler
																}
																value={
																	this.state
																		.createForm[
																		"meta_tags"
																	]
																}
																name="meta_tags"
																placeholder="Comma Seprated Keywords"
															/>
														</Form.Group>

														<Form.Group className="col-md-12">
															<Form.Label>
																Contact Number1
															</Form.Label>
															{this.validator.message(
																"phone",
																this.state
																	.createForm[
																	"phone"
																],
																"required|phone",
																{
																	className:
																		"text-danger",
																}
															)}

															<InputGroup>
																<Form.Control
																	type="text"
																	onChange={
																		this
																			.createFormHandler
																	}
																	value={
																		this.state
																			.createForm[
																			"phone"
																		]
																	}
																	name="phone"
																	placeholder="Landline/Mobile Number"
																/>
															</InputGroup>
														</Form.Group>
														<Form.Group className="col-md-12">
															<Form.Label>
																Contact Number2
															</Form.Label>
															{this.validator.message(
																"alternative_phone",
																this.state
																	.createForm[
																	"alternative_phone"
																],
																"phone",
																{
																	className:
																		"text-danger",
																}
															)}

															<InputGroup>
																<Form.Control
																	type="text"
																	onChange={
																		this
																			.createFormHandler
																	}
																	value={
																		this.state
																			.createForm[
																			"alternative_phone"
																		]
																	}
																	name="alternative_phone"
																	placeholder="Alternative Number"
																/>
															</InputGroup>
														</Form.Group>

														<Form.Group className="col-md-12">
															<Form.Label>
																Store Email
															</Form.Label>
															{this.validator.message(
																"store_email",
																this.state
																	.createForm[
																	"store_email"
																],
																"required|email",
																{
																	className:
																		"text-danger",
																}
															)}

															<InputGroup>
																<Form.Control
																	type="email"
																	value={
																		this.state
																			.createForm[
																			"store_email"
																		]
																	}
																	name="store_email"
																	onChange={
																		this
																			.createFormHandler
																	}
																	placeholder="Valid Email Id"
																/>
															</InputGroup>
														</Form.Group>
														<Form.Group className="col-md-12">
															<Form.Label>
																Notification Email
															</Form.Label>
															{this.validator.message(
																"order_notification_email",
																this.state
																	.createForm[
																	"order_notification_email"
																],
																"required|email",
																{
																	className:
																		"text-danger",
																}
															)}

															<InputGroup>
																<Form.Control
																	type="email"
																	value={
																		this.state
																			.createForm[
																			"order_notification_email"
																		]
																	}
																	name="order_notification_email"
																	onChange={
																		this
																			.createFormHandler
																	}
																	placeholder="Valid Email Id"
																/>
															</InputGroup>
														</Form.Group>

														<SearchLocationInput
															setBack={this.handleMap}
															address={
																this.state
																	.createForm[
																	"address"
																]
															}
														/>

														<Form.Group className="col-md-12">
															<Form.Label>
																Average Preparation
																Time for Takeout
															</Form.Label>
															<Form.Control
																type="text"
																onChange={
																	this
																		.createFormHandler
																}
																value={
																	this.state
																		.createForm[
																		"avg_preparation_time"
																	]
																}
																name="avg_preparation_time"
																placeholder=" e.g. 20 Min-40 Min"
															/>
														</Form.Group>
														<Form.Group className="col-md-12">
															<Form.Label>
																Average Preparation
																Time for Delivery
															</Form.Label>
															<Form.Control
																type="text"
																onChange={
																	this
																		.createFormHandler
																}
																value={
																	this.state
																		.createForm[
																		"preparation_time_for_delivery"
																	]
																}
																name="preparation_time_for_delivery"
																placeholder=" e.g. 20 Min-40 Min"
															/>
														</Form.Group>
														<Form.Group className="col-md-12">
															<Form.Label>
																{" "}
																Order Response
																Time(Displayable in
																Customer Order
																success Message)
															</Form.Label>
															<Form.Control
																type="number"
																onChange={
																	this
																		.createFormHandler
																}
																value={
																	this.state
																		.createForm[
																		"takeout_response_time"
																	]
																}
																name="takeout_response_time"
																max={180}
																min={10}
															/>
														</Form.Group>

														<Form.Group className="col-md-4">
															<Form.Check
																type="checkbox"
																name="order_email_notification"
																checked={
																	this.state
																		.createForm[
																		"order_email_notification"
																	]
																}
																onClick={
																	this
																		.createFormHandler
																}
																label={
																	"Notify if order delayed to accept"
																}
															/>{" "}
														</Form.Group>
														<Form.Group className="col-md-12">
															<Form.Label>
																Delivery Team ID
															</Form.Label>
															<Form.Control
																type="text"
																onChange={
																	this
																		.createFormHandler
																}
																value={
																	this.state
																		.createForm[
																		"delivery_team_id"
																	]
																}
																name="delivery_team_id"
																placeholder=" Team id"
															/>
														</Form.Group>

														<Form.Group className="col-md-12">
															<Form.Label>
																GST Number
															</Form.Label>
															<Form.Control
																type="text"
																onChange={
																	this
																		.createFormHandler
																}
																value={
																	this.state
																		.createForm[
																		"gst_number"
																	]
																}
																name="gst_number"
																placeholder=" GST number"
															/>
														</Form.Group>
														<Form.Group className="col-md-12">
															<Form.Label>
																PST Number
															</Form.Label>
															<Form.Control
																type="text"
																onChange={
																	this
																		.createFormHandler
																}
																value={
																	this.state
																		.createForm[
																		"pst_number"
																	]
																}
																name="pst_number"
																placeholder=" PST number"
															/>
														</Form.Group>
														<Form.Group controlId="formBasicCheckbox">
															<Form.Check
																type="checkbox"
																name="tax_enabled"
																checked={
																	this.state
																		.createForm[
																		"tax_enabled"
																	]
																}
																onClick={
																	this
																		.createFormHandler
																}
																label="tax applicable"
															/>
														</Form.Group>

														<Form.Group
															className="col-md-12"
															hidden={
																!this.state
																	.createForm[
																	"tax_enabled"
																]
															}
														>
															<Form.Label>
																{" "}
																Applicable Tax
															</Form.Label>

															<Form.Control
																as="select"
																name="appliicable_tax"
																onChange={
																	this.onSelectTax
																}
															>
																{this.state
																	.taxOptions &&
																	this.state.taxOptions.map(
																		(
																			taxObj
																		) => (
																			<option
																				selected={
																					this
																						.state
																						.createForm[
																						"appliicable_tax"
																					] ==
																					taxObj.id
																						? true
																						: false
																				}
																				value={
																					taxObj.id
																				}
																			>
																				{
																					taxObj.name
																				}
																			</option>
																		)
																	)}
															</Form.Control>
														</Form.Group>

														<Form.Group className="col-md-8">
															<Form.Label>
																Select Paymentmode
															</Form.Label>

															<Form.Control
																as="select"
																name="online_payment_mode"
																value={
																	this.state
																		.createForm[
																		"online_payment_mode"
																	]
																}
																onChange={(e) => {
																	this.handlePaymentModeChange(
																		e
																	);
																}}
															>
																<option value={1}>
																	{
																		"Stripe Checkout Option"
																	}
																</option>
																<option value={2}>
																	{
																		"Moneris Hosted Page"
																	}
																</option>
																<option value={3}>
																	{
																		"Moneris Checkout"
																	}
																</option>
															</Form.Control>
														</Form.Group>
														<Form.Group className="col-md-8">
															<Form.Label>
																Store ID
															</Form.Label>
															<InputGroup>
																<Form.Control
																	type="store_id"
																	onChange={
																		this
																			.createFormHandler
																	}
																	name="store_id"
																	value={
																		this.state
																			.createForm[
																			"store_id"
																		]
																	}
																	placeholder="store_id"
																/>
															</InputGroup>
														</Form.Group>

														<Form.Group className="col-md-8">
															<Form.Label>
																Checkout ID
															</Form.Label>
															<InputGroup>
																<Form.Control
																	type="checkout_id"
																	onChange={
																		this
																			.createFormHandler
																	}
																	name="checkout_id"
																	value={
																		this.state
																			.createForm[
																			"checkout_id"
																		]
																	}
																	placeholder="checkout_id"
																/>
															</InputGroup>
														</Form.Group>

														<Form.Group className="col-md-8">
															<Form.Label>
																API TOKEN
															</Form.Label>
															<InputGroup>
																<Form.Control
																	type="api_token"
																	onChange={
																		this
																			.createFormHandler
																	}
																	name="api_token"
																	value={
																		this.state
																			.createForm[
																			"api_token"
																		]
																	}
																	placeholder="api_token"
																/>
															</InputGroup>
														</Form.Group>
														<Form.Group className="col-md-12">
															<Form.Label>
																Provider URL
															</Form.Label>
															<InputGroup>
																<Form.Control
																	type="payment_provided_url"
																	onChange={
																		this
																			.createFormHandler
																	}
																	name="payment_provided_url"
																	value={
																		this.state
																			.createForm[
																			"payment_provided_url"
																		]
																	}
																	placeholder="Provider URL"
																/>
																<InputGroup.Append>
																	<Button
																		variant="outline-secondary"
																		type="button"
																		id="button-addon2"
																	>
																		<Icofont icon="web" />
																	</Button>
																</InputGroup.Append>
															</InputGroup>
														</Form.Group>

														<Form.Group className="col-md-12">
															<Form.Label>
																APP ID
															</Form.Label>
															<Form.Control
																type="text"
																name="payment_app_id"
																value={
																	this.state
																		.createForm[
																		"payment_app_id"
																	]
																}
																onChange={
																	this
																		.createFormHandler
																}
																name="payment_app_id"
																placeholder="APP id"
															/>
														</Form.Group>

														<Form.Group className="col-md-12">
															<Form.Label>
																HPP KEY
															</Form.Label>
															<Form.Control
																type="text"
																name="hpp_key"
																onChange={
																	this
																		.createFormHandler
																}
																value={
																	this.state
																		.createForm[
																		"hpp_key"
																	]
																}
																name="hpp_key"
																placeholder="HPP KEY"
															/>
														</Form.Group>
														<Form.Group className="col-md-12">
															<Form.Label>
																Stripe Public Key
															</Form.Label>
															<Form.Control
																type="text"
																name="stripe_key"
																onChange={
																	this
																		.createFormHandler
																}
																value={
																	this.state
																		.createForm[
																		"stripe_key"
																	]
																}
																name="stripe_key"
																placeholder="Stripe KEY"
															/>
														</Form.Group>
														<Form.Group className="col-md-12">
															<Form.Label>
																Stripe Secret Key
															</Form.Label>
															<Form.Control
																type="password"
																name="stripe_secret_key"
																onChange={
																	this
																		.createFormHandler
																}
																value={
																	this.state
																		.createForm[
																		"stripe_secret_key"
																	]
																}
																name="stripe_secret_key"
																placeholder="Stripe Secret KEY"
															/>
														</Form.Group>
														<Form.Group className="col-md-12">
															<Form.Label>
																Time Zone
															</Form.Label>

															<Form.Control
																as="select"
																name="time_zone"
																onChange={
																	this
																		.handleTimeZone
																}
																defaultValue={
																	this.state
																		.createForm[
																		"time_zone"
																	]
																}
															>
																{timeZoneList.map(
																	(timeObj) => (
																		<option
																			selected={
																				this
																					.state
																					.createForm[
																					"time_zone"
																				] ===
																				timeObj
																					? true
																					: false
																			}
																			value={
																				timeObj
																			}
																		>
																			{
																				timeObj
																			}
																		</option>
																	)
																)}
															</Form.Control>
														</Form.Group>
														<Form.Group className="col-md-12">
															<Form.Check
																type="checkbox"
																name="enable_pay_collection"
																checked={
																	this.state
																		.createForm[
																		"enable_pay_collection"
																	]
																}
																onClick={
																	this
																		.createFormHandler
																}
																label={
																	"Enable Payment Collection"
																}
															/>{" "}
														</Form.Group>
														{/* 7start */}
														{!this.state.createForm[
															"enable_draw_delivery_zone"
														] && (
															<Form.Group className="col-md-12">
																<Form.Label>
																	{" "}
																	Minim Order
																	Charge required
																	to enable
																	delivery
																</Form.Label>
																<InputGroup>
																	<Form.Control
																		type="text"
																		name="delevery_enabling_charge"
																		value={
																			this
																				.state
																				.createForm[
																				"delevery_enabling_charge"
																			]
																		}
																		onChange={
																			this
																				.createFormHandler
																		}
																		placeholder="delevery_enabling_charge"
																	/>
																</InputGroup>
															</Form.Group>
														)}
														{/* 7end */}
														<Form.Group
															className="col-md-12"
															hidden={
																!this.state
																	.createForm[
																	"tax_enabled"
																]
															}
														>
															<Form.Label>
																{" "}
																Delivery Partner
															</Form.Label>
															<Form.Control
																as="select"
																name="delivery_partner"
																onChange={
																	this
																		.handleDeliveryPartner
																}
															>
																<option
																	selected={
																		!this.state
																			.createForm[
																			"delivery_partner"
																		] ||
																		this.state
																			.createForm[
																			"delivery_partner"
																		] == 0
																			? true
																			: false
																	}
																	value={0}
																>
																	None
																</option>
																{this.state
																	.delOptions &&
																	this.state.delOptions.map(
																		(
																			delObj
																		) => (
																			<option
																				selected={
																					this
																						.state
																						.createForm[
																						"delivery_partner"
																					] ==
																					delObj.id
																						? true
																						: false
																				}
																				value={
																					delObj.id
																				}
																			>
																				{
																					delObj.title
																				}
																			</option>
																		)
																	)}
															</Form.Control>
														</Form.Group>
														{/* 7start */}
														{!this.state.createForm[
															"enable_draw_delivery_zone"
														] && (
															<>
																<Form.Group className="col-md-6">
																	<Form.Label>
																		{" "}
																		Deliverable
																		Distance
																		Limit in
																		Minimum Zone
																		(KiloMeters)
																	</Form.Label>
																	<InputGroup>
																		<Form.Control
																			type="number"
																			name="minim_delevery"
																			min={0}
																			max={
																				this
																					.state
																					.createForm[
																					"maxim_delevery"
																				]
																			}
																			value={
																				this
																					.state
																					.createForm[
																					"minim_delevery"
																				]
																			}
																			onChange={
																				this
																					.createFormHandler
																			}
																			placeholder="Delivarable Distance"
																		/>
																	</InputGroup>
																</Form.Group>
																<Form.Group className="col-md-6">
																	<Form.Label>
																		{" "}
																		Deliverable
																		Distance
																		Limit in
																		Maximum Zone
																		(KiloMeters)
																	</Form.Label>
																	<InputGroup>
																		<Form.Control
																			type="number"
																			name="maxim_delevery"
																			min={
																				this
																					.state
																					.createForm[
																					"minim_delevery"
																				]
																			}
																			max={
																				500
																			}
																			value={
																				this
																					.state
																					.createForm[
																					"maxim_delevery"
																				]
																			}
																			onChange={
																				this
																					.createFormHandler
																			}
																			placeholder="Delivarable Distance"
																		/>
																	</InputGroup>
																</Form.Group>
																<Form.Group className="col-md-6">
																	<Form.Label>
																		{" "}
																		Delivery
																		Charge for
																		Minimum Zone
																		applicable
																		to orders in
																		(0-
																		{
																			this
																				.state
																				.createForm[
																				"minim_delevery"
																			]
																		}
																		) distance
																	</Form.Label>
																	<InputGroup>
																		<Form.Control
																			type="text"
																			name="delevery_charge"
																			value={
																				this
																					.state
																					.createForm[
																					"delevery_charge"
																				]
																			}
																			onChange={
																				this
																					.createFormHandler
																			}
																			placeholder="delivery amount"
																		/>
																	</InputGroup>
																</Form.Group>
																<Form.Group className="col-md-6">
																	<Form.Label>
																		{" "}
																		Delivery
																		Charge for
																		Maximum Zone
																		applicable
																		to orders in
																		(
																		{
																			this
																				.state
																				.createForm[
																				"minim_delevery"
																			]
																		}
																		-
																		{
																			this
																				.state
																				.createForm[
																				"maxim_delevery"
																			]
																		}
																		) distance
																	</Form.Label>
																	<InputGroup>
																		<Form.Control
																			type="text"
																			name="max_delevery_charge"
																			value={
																				this
																					.state
																					.createForm[
																					"max_delevery_charge"
																				]
																			}
																			onChange={
																				this
																					.createFormHandler
																			}
																			placeholder="delivery amount"
																		/>
																	</InputGroup>
																</Form.Group>

																<Form.Group className="col-md-4">
																	<Form.Check
																		type="checkbox"
																		name="enable_delevery_free"
																		checked={
																			this
																				.state
																				.createForm[
																				"enable_delevery_free"
																			]
																		}
																		onClick={
																			this
																				.createFormHandler
																		}
																		label={
																			"Enable Free Delivery"
																		}
																	/>{" "}
																</Form.Group>
																{this.state
																	.createForm[
																	"enable_delevery_free"
																] == true && (
																	<Form.Group className="col-md-12">
																		<Form.Label>
																			(Minimum
																			Zone)
																			Make
																			Delivery
																			Free for
																			After
																		</Form.Label>
																		<Form.Control
																			type="text"
																			value={
																				this
																					.state
																					.createForm[
																					"delevery_free"
																				]
																			}
																			onChange={
																				this
																					.createFormHandler
																			}
																			name="delevery_free"
																			placeholder="amount"
																		/>
																	</Form.Group>
																)}
																{this.state
																	.createForm[
																	"enable_delevery_free"
																] == true && (
																	<Form.Group className="col-md-12">
																		<Form.Label>
																			(Maximum
																			Zone)
																			Make
																			Delivery
																			Free for
																			After
																		</Form.Label>
																		<Form.Control
																			type="text"
																			value={
																				this
																					.state
																					.createForm[
																					"max_delevery_free"
																				]
																			}
																			onChange={
																				this
																					.createFormHandler
																			}
																			name="max_delevery_free"
																			placeholder="amount"
																		/>
																	</Form.Group>
																)}
															</>
														)}
														{/* 7end */}
														{/* 7start */}
														<Form.Group className="col-md-12">
															<Form.Check
																type="checkbox"
																name="enable_draw_delivery_zone"
																checked={
																	this.state
																		.createForm[
																		"enable_draw_delivery_zone"
																	]
																}
																onClick={
																	this
																		.createFormHandler
																}
																label={
																	"Enable Draw Delivery Zone"
																}
															/>
														</Form.Group>
														{this.state.createForm[
															"enable_draw_delivery_zone"
														] && (
															<div
																className="bg-white rounded shadow-sm p-4 mb-4"
																style={{
																	width: "100%",
																}}
															>
																<Row>
																	<Col>
																		<h6>
																			Custom
																			Draw
																			Delivery
																			Zone
																		</h6>
																	</Col>
																	<Col>
																		<button
																			type="button"
																			onClick={
																				this
																					.handleAddZone
																			}
																		>
																			+ Add
																			New Zone
																		</button>
																	</Col>
																</Row>
																<br />
																<Row>
																	<Col md={2}>
																		Title
																	</Col>
																	<Col md={2}>
																		Delivery
																		Charge
																	</Col>
																	<Col md={2}>
																		Delivery
																		Enabling
																		Order Value
																	</Col>
																	<Col md={1}>
																		Enable Free
																		Delivery
																	</Col>
																	<Col md={2}>
																		Delivery
																		Free After
																		Order
																	</Col>
																	<Col md={1}>
																		Zone
																	</Col>
																	<Col md={1}>
																		Enable Zone
																	</Col>
																	<Col md={1}>
																		Remove
																	</Col>
																</Row>
																{this.state.deliveryZones.map(
																	(zone, i) => {
																		if (
																			!zone[
																				"remove"
																			]
																		) {
																			return (
																				<Row>
																					<Col
																						md={
																							2
																						}
																					>
																						<Form.Group>
																							<InputGroup>
																								<Form.Control
																									type="text"
																									name="delivery_zone_title"
																									placeholder="Zone Title"
																									value={
																										zone.title
																									}
																									onChange={(
																										e
																									) => {
																										this.handleDeliveryZoneChanges(
																											i,
																											e
																										);
																									}}
																								/>
																							</InputGroup>
																						</Form.Group>
																					</Col>
																					<Col
																						md={
																							2
																						}
																					>
																						<Form.Group>
																							<InputGroup>
																								<Form.Control
																									type="text"
																									name="delivery_zone_charge"
																									placeholder="Delivery Charge"
																									value={
																										zone.delivery_charge
																									}
																									onChange={(
																										e
																									) => {
																										this.handleDeliveryZoneChanges(
																											i,
																											e
																										);
																									}}
																								/>
																							</InputGroup>
																						</Form.Group>
																					</Col>
																					<Col
																						md={
																							2
																						}
																					>
																						<Form.Group>
																							<InputGroup>
																								<Form.Control
																									type="text"
																									name="delivery_enabling_order_value"
																									placeholder="Delivery Enabling Order Value"
																									value={
																										zone.delivery_enabling_order_value
																									}
																									onChange={(
																										e
																									) => {
																										this.handleDeliveryZoneChanges(
																											i,
																											e
																										);
																									}}
																								/>
																							</InputGroup>
																						</Form.Group>
																					</Col>
																					<Col
																						md={
																							1
																						}
																					>
																						<Form.Group>
																							<Form.Check
																								type="checkbox"
																								name="enable_delivery_free"
																								checked={
																									zone.enable_delivery_free
																								}
																								onClick={(
																									e
																								) => {
																									this.handleDeliveryZoneChanges(
																										i,
																										e
																									);
																								}}
																							/>
																						</Form.Group>
																					</Col>
																					<Col
																						md={
																							2
																						}
																					>
																						<Form.Group>
																							<InputGroup>
																								<Form.Control
																									type="text"
																									name="delivery_free_min_order_value"
																									placeholder="Free After"
																									value={
																										zone.delivery_free_min_order_value
																									}
																									onChange={(
																										e
																									) => {
																										this.handleDeliveryZoneChanges(
																											i,
																											e
																										);
																									}}
																								/>
																							</InputGroup>
																						</Form.Group>
																					</Col>
																					<Col
																						md={
																							1
																						}
																					>
																						<button
																							type="button"
																							name="draw_delivery_zone"
																							onClick={() => {
																								this.onClickDrawZone(
																									i
																								);
																							}}
																						>
																							{zone.coordinates ===
																							null
																								? "Draw Zone"
																								: "Update Zone"}
																						</button>
																					</Col>
																					<Col
																						md={
																							1
																						}
																					>
																						<Form.Group>
																							<Form.Check
																								type="checkbox"
																								name="enable_delivery_zone"
																								checked={
																									zone.is_enabled
																								}
																								onClick={(
																									e
																								) => {
																									this.handleDeliveryZoneChanges(
																										i,
																										e
																									);
																								}}
																							/>
																						</Form.Group>
																					</Col>
																					<Col
																						md={
																							1
																						}
																					>
																						<button
																							type="button"
																							style={{
																								color: "red",
																							}}
																							onClick={() => {
																								confirmDeleteAction(
																									"Zone"
																								).then(
																									({
																										isConfirmed,
																									}) => {
																										isConfirmed &&
																											this.handleRemoveZone(
																												i
																											);
																									}
																								);
																							}}
																						>
																							-
																						</button>
																					</Col>
																				</Row>
																			);
																		}
																	}
																)}
																<div
																	className="delivery-zone-map1"
																	style={{
																		textAlign:
																			"center",
																		height: "100vh",
																		fontFamily:
																			"sans-serif",
																		width: "100%",
																		marginTop:
																			"12px",
																	}}
																>
																	{/* <LoadScript id="script-loader" googleMapsApiKey="" language="en" region="us" libraries={["geometry"]}> */}
																	<GoogleMap
																		mapContainerClassName="google-map-display1"
																		center={{
																			lat: Number(
																				this
																					.state
																					.createForm[
																					"addressLat"
																				]
																			),
																			lng: Number(
																				this
																					.state
																					.createForm[
																					"addressLng"
																				]
																			),
																		}}
																		zoom={15}
																		version="weekly"
																		mapContainerStyle={{
																			height: "100%",
																			width: "100%",
																		}}
																	>
																		{this.state.deliveryZones.map(
																			(
																				zone
																			) => {
																				if (
																					zone.is_enabled &&
																					!zone[
																						"remove"
																					]
																				) {
																					let cord =
																						jsonParse(
																							zone.coordinates
																						);
																					if (
																						cord &&
																						cord.length >
																							2
																					) {
																						return (
																							<>
																								<Polygon
																									path={
																										cord
																									}
																									options={{
																										fillColor:
																											"blue",
																										fillOpacity: 0.3,
																									}}
																								/>
																								<Marker
																									position={
																										cord[0]
																									}
																									label={{
																										text: zone.title
																											? zone.title
																											: "!",
																										fontSize:
																											"20px",
																										fontWeight:
																											"bold",
																									}}
																								></Marker>
																							</>
																						);
																					}
																				}
																			}
																		)}
																	</GoogleMap>
																	{/* </LoadScript> */}
																</div>
															</div>
														)}
														{/* 7end */}
														<Form.Group className="col-md-4">
															<Form.Check
																type="checkbox"
																name="enable_takeout_discount"
																checked={
																	this.state
																		.createForm[
																		"enable_takeout_discount"
																	]
																}
																onClick={
																	this
																		.createFormHandler
																}
																label={
																	"Enable Takeout Discount"
																}
															/>{" "}
														</Form.Group>
														{this.state.createForm[
															"enable_takeout_discount"
														] == true && (
															<Form.Group className="col-md-12">
																<Form.Label>
																	{" "}
																	Apply Takeout
																	Discount
																	Percent:{" "}
																</Form.Label>
																<InputGroup>
																	<Form.Control
																		type="number"
																		name="take_out_discount"
																		value={
																			this
																				.state
																				.createForm[
																				"take_out_discount"
																			]
																		}
																		onChange={
																			this
																				.createFormHandler
																		}
																		placeholder="Takeout Discount Percentage"
																	/>
																</InputGroup>
															</Form.Group>
														)}
														{this.state.createForm[
															"enable_takeout_discount"
														] == true && (
															<Form.Group className="col-md-12">
																<Form.Label>
																	{" "}
																	Apply Takeout
																	Discount minimum
																	value
																</Form.Label>
																<Form.Control
																	type="text"
																	value={
																		this.state
																			.createForm[
																			"take_out_discount_minim_value"
																		]
																	}
																	onChange={
																		this
																			.createFormHandler
																	}
																	name="take_out_discount_minim_value"
																	placeholder="amount"
																/>
															</Form.Group>
														)}
														<Form.Group className="col-md-12">
															<Form.Label>
																Delivery
																Instructions
															</Form.Label>
															<InputGroup>
																<Form.Control
																	type="textarea"
																	onChange={
																		this
																			.createFormHandler
																	}
																	value={
																		this.state
																			.createForm[
																			"delivery_instructions"
																		]
																	}
																	name="delivery_instructions"
																	placeholder="Delivery Instructions"
																/>
															</InputGroup>
														</Form.Group>
														<Form.Group className="col-md-12">
															<Form.Check
																type="checkbox"
																name="enable_tip_in_tookan_online_orders"
																checked={
																	this.state
																		.createForm[
																		"enable_tip_in_tookan_online_orders"
																	]
																}
																onClick={
																	this
																		.createFormHandler
																}
																label={
																	"Enable Tip In Tookan For Online Orders"
																}
															/>{" "}
														</Form.Group>
													</div>
												</Form>
											</Col>
										</Row>
									</div>
									<div className="pt-2"></div>

									<div className="bg-white rounded shadow-sm p-4 mb-4">
										<Row>
											<Form.Group className="col-md-6">
												<Form.Check
													type="checkbox"
													name="disable_delivery"
													checked={
														this.state.createForm[
															"disable_delivery"
														]
													}
													onClick={this.createFormHandler}
													label={"Disable Delivery"}
												/>{" "}
											</Form.Group>

											<Form.Group className="col-md-6">
												<Form.Check
													type="checkbox"
													name="disable_takeout"
													checked={
														this.state.createForm[
															"disable_takeout"
														]
													}
													onClick={this.createFormHandler}
													label={"Disable Takeout"}
												/>{" "}
											</Form.Group>

											{this.state.createForm[
												"disable_delivery"
											] === false && (
												<Form.Group className="col-md-6">
													<Form.Check
														type="checkbox"
														name="disable_pay_on_delivery"
														checked={
															this.state.createForm[
																"disable_pay_on_delivery"
															]
														}
														onClick={
															this.createFormHandler
														}
														label={
															"Disable pay on delivery"
														}
													/>{" "}
												</Form.Group>
											)}

											{this.state.createForm[
												"disable_takeout"
											] === false && (
												<Form.Group className="col-md-6">
													<Form.Check
														type="checkbox"
														name="disable_pay_at_store"
														checked={
															this.state.createForm[
																"disable_pay_at_store"
															]
														}
														onClick={
															this.createFormHandler
														}
														label={
															"Disable pay at store"
														}
													/>
												</Form.Group>
											)}

											{this.state.createForm[
												"disable_delivery"
											] === false && (
												<Form.Group className="col-md-6">
													<Form.Check
														type="checkbox"
														name="enable_auto_delivery_request"
														checked={
															this.state.createForm[
																"enable_auto_delivery_request"
															]
														}
														onClick={
															this.createFormHandler
														}
														label={
															"Enable auto delivery request"
														}
													/>
												</Form.Group>
											)}
										</Row>
										<Row>
											<Col md={4}>
												<Form.Group className="col-md-12">
													<Form.Check
														type="checkbox"
														name="close_restaurent"
														checked={
															this.state.createForm[
																"close_restaurent"
															]
														}
														onClick={(event) => {
															var val =
																event.target
																	.checked;
															if (val == true) {
																confirmAction(
																	"Warning",
																	"Hey ! Restaurent web page will be closed.Are you sure to Proceed?",
																	"Yest "
																).then(
																	(
																		userResponse,
																		event
																	) => {
																		console.log(
																			userResponse
																		);
																		if (
																			userResponse.isConfirmed ==
																			true
																		) {
																			let inputForm =
																				{
																					...this
																						.state
																						.createForm,
																				};
																			inputForm[
																				"close_restaurent"
																			] = true;
																			inputForm[
																				"enable_pop_up"
																			] = true;
																			inputForm[
																				"message_pop_up"
																			] =
																				"Sorry! We are closed for maintenance";

																			this.setState(
																				{
																					createForm:
																						inputForm,
																				}
																			);
																		}
																	}
																);
															} else {
																let inputForm = {
																	...this.state
																		.createForm,
																};

																inputForm[
																	"close_restaurent"
																] = false;
																inputForm[
																	"enable_pop_up"
																] = false;
																inputForm[
																	"message_pop_up"
																] = "";

																this.setState({
																	createForm:
																		inputForm,
																});
															}
														}}
														label={
															<Badge
																className={
																	"text-danger"
																}
															>
																Close Restaurent
															</Badge>
														}
													/>{" "}
												</Form.Group>
											</Col>
										</Row>
										{this.state.createForm[
											"close_restaurent"
										] == true && (
											<Row>
												{/*	<Col md={4} >
								<Form.Group className="col-md-4">
									
								
				  <Form.Check
                                type="checkbox"
                                name="enable_pop_up"
                                checked={
                                  this.state.createForm["enable_pop_up"]
                                }
                                onClick={this.createFormHandler}
                                label="Enable Popup Message"
                              /> </Form.Group>
	<Label>Closing Popup Message</Label>
									</Col>*/}
												<Col md={12}>
													<Form.Label>
														{" "}
														Closing Message
													</Form.Label>

													<Form.Control
														type="textarea"
														onChange={
															this.createFormHandler
														}
														rows={3}
														value={
															this.state.createForm[
																"message_pop_up"
															]
														}
														name="message_pop_up"
														placeholder="popup Message to be displayed"
													/>
												</Col>
											</Row>
										)}
									</div>
									<div className="pt-2"></div>

									<div className="bg-white rounded shadow-sm p-4 mb-4">
										<Form.Group className="">
											<Form.Check
												type="checkbox"
												name="enable_separate_delivery_schedule"
												checked={
													this.state.createForm[
														"enable_separate_delivery_schedule"
													]
												}
												onClick={this.createFormHandler}
												label={
													"Enable Separate Delivery Schedule"
												}
											/>
										</Form.Group>
										{this.state.createForm[
											"enable_separate_delivery_schedule"
										] ? (
											<h4>TakeOut Opening Hours</h4>
										) : (
											<h4>
												TakeOut & Delivery Opening Hours
											</h4>
										)}
										<Row>
											<Col md={3}> DAY </Col>
											<Col md={3}> OPENING TIME </Col>
											<Col md={3}> CLOSING TIME </Col>
											<Col md={1}></Col>
											<Col md={1}></Col>
											<Col md={1}>
												<button
													className="ml-1"
													size="sm"
													color="success"
													onClick={this.handleAddShedule}
												>
													+
												</button>
											</Col>
										</Row>

										{this.state.dailyShedules.map(
											(sheduleObj, i) => {
												if (
													!sheduleObj["remove"] &&
													sheduleObj["order_mode"] === 1
												) {
													return (
														<Row>
															<Col md={3}>
																<Form.Group className="">
																	<Form.Control
																		as="select"
																		name="day"
																		value={
																			sheduleObj[
																				"day"
																			]
																		}
																		onChange={(
																			e
																		) => {
																			this.handleDayChange(
																				i,
																				e
																			);
																		}}
																	>
																		{weekDays.map(
																			(
																				dayObj
																			) => {
																				return (
																					<option
																						value={
																							dayObj
																						}
																					>
																						{
																							dayObj
																						}
																					</option>
																				);
																			}
																		)}
																	</Form.Control>
																</Form.Group>
															</Col>
															<Col md={3}>
																<TimePicker
																	step={time_step}
																	onChange={(
																		time
																	) => {
																		this.dayTimeSheduleChange(
																			i,
																			time,
																			"opening_time"
																		);
																	}}
																	value={
																		sheduleObj[
																			"opening_time"
																		]
																	}
																/>{" "}
															</Col>
															<Col md={3}>
																<TimePicker
																	step={time_step}
																	// start={toFormattedDate24(sheduleObj['opening_time'])}
																	onChange={(
																		time
																	) => {
																		this.dayTimeSheduleChange(
																			i,
																			time,
																			"closing_time"
																		);
																	}}
																	value={
																		sheduleObj[
																			"closing_time"
																		]
																	}
																/>
															</Col>
															<Col md={1}>
																{
																	<button
																		size="sm"
																		className="text-danger"
																		onClick={() => {
																			this.handleRemoveShedule(
																				i
																			);
																		}}
																	>
																		-
																	</button>
																}
															</Col>
															<Col md={1}>
																{
																	<button
																		size="sm"
																		className="ml-1"
																		onClick={() => {
																			this.handleAddShedule(
																				{
																					copy: true,
																					values: {
																						day: sheduleObj[
																							"day"
																						],
																						opening_time:
																							sheduleObj[
																								"opening_time"
																							],
																						closing_time:
																							sheduleObj[
																								"closing_time"
																							],
																					},
																				}
																			);
																		}}
																	>
																		<Icofont icon="copy" />
																	</button>
																}
															</Col>
															<Col md={1}></Col>
														</Row>
													);
												}
											}
										)}

										{this.state.createForm[
											"enable_separate_delivery_schedule"
										] && (
											<>
												<h4>Delivery Opening Hours</h4>
												<Row>
													<Col md={3}> DAY </Col>
													<Col md={3}> OPENING TIME </Col>
													<Col md={3}> CLOSING TIME </Col>
													<Col md={1}></Col>
													<Col md={1}></Col>
													<Col md={1}>
														<button
															className="ml-1"
															size="sm"
															color="success"
															onClick={() => {
																this.handleAddShedule(
																	null,
																	0
																);
															}}
														>
															+
														</button>
													</Col>
												</Row>
												{this.state.dailyShedules.map(
													(sheduleObj, i) => {
														if (
															!sheduleObj["remove"] &&
															sheduleObj[
																"order_mode"
															] === 0
														) {
															return (
																<Row>
																	<Col md={3}>
																		<Form.Group className="">
																			<Form.Control
																				as="select"
																				name="day"
																				value={
																					sheduleObj[
																						"day"
																					]
																				}
																				onChange={(
																					e
																				) => {
																					this.handleDayChange(
																						i,
																						e
																					);
																				}}
																			>
																				{weekDays.map(
																					(
																						dayObj
																					) => {
																						return (
																							<option
																								value={
																									dayObj
																								}
																							>
																								{
																									dayObj
																								}
																							</option>
																						);
																					}
																				)}
																			</Form.Control>
																		</Form.Group>
																	</Col>
																	<Col md={3}>
																		<TimePicker
																			step={
																				time_step
																			}
																			onChange={(
																				time
																			) => {
																				this.dayTimeSheduleChange(
																					i,
																					time,
																					"opening_time"
																				);
																			}}
																			value={
																				sheduleObj[
																					"opening_time"
																				]
																			}
																		/>
																	</Col>
																	<Col md={3}>
																		<TimePicker
																			step={
																				time_step
																			}
																			onChange={(
																				time
																			) => {
																				this.dayTimeSheduleChange(
																					i,
																					time,
																					"closing_time"
																				);
																			}}
																			value={
																				sheduleObj[
																					"closing_time"
																				]
																			}
																		/>
																	</Col>
																	<Col md={1}>
																		{
																			<button
																				size="sm"
																				className="text-danger"
																				onClick={() => {
																					this.handleRemoveShedule(
																						i
																					);
																				}}
																			>
																				-
																			</button>
																		}
																	</Col>
																	<Col md={1}>
																		{
																			<button
																				size="sm"
																				className="ml-1"
																				onClick={() => {
																					this.handleAddShedule(
																						{
																							copy: true,
																							values: {
																								day: sheduleObj[
																									"day"
																								],
																								opening_time:
																									sheduleObj[
																										"opening_time"
																									],
																								closing_time:
																									sheduleObj[
																										"closing_time"
																									],
																							},
																						},
																						0
																					);
																				}}
																			>
																				<Icofont icon="copy" />
																			</button>
																		}
																	</Col>
																	<Col
																		md={1}
																	></Col>
																</Row>
															);
														}
													}
												)}
											</>
										)}
									</div>

									<div className="pt-2"></div>
									<div className="bg-white rounded shadow-sm p-4 osahan-payment">
										<div>
											<FileUploader
												onFileUpload={this.handleFiles}
											/>

											<h4>Gallery Images</h4>

											{typeof this.state.galleryImages !=
												"undefined" &&
												this.state.galleryImages.length >
													0 &&
												this.state.galleryImages.map(
													(imageObj, idx) => {
														return (
															<div
																className="p-1 px-2"
																key={idx}
															>
																<Row className="align-items-center">
																	<Col className="pl-0">
																		<img
																			height="50px"
																			width="100px"
																			alt={
																				"image"
																			}
																			src={
																				imageObj.image
																			}
																		/>

																		<a
																			id="id1"
																			onClick={() =>
																				this.deleteImage(
																					imageObj.id
																				)
																			}
																			className="btn btn-link text-danger btn-lg p-0 ml-1"
																		>
																			X
																		</a>
																	</Col>
																</Row>
															</div>
														);
													}
												)}
										</div>
									</div>

									<div className="bg-white rounded shadow-sm p-4 mb-4">
										<Row>
											<Col md={6}></Col>
											<Col md={6}>
												<Button
													onClick={this.updateProfile}
												>
													Update
												</Button>
											</Col>
										</Row>
									</div>
								</span>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default ProfileEdit;
