import React from "react";
import OrderCard from "../common/OrderCard";
import Icofont from "react-icofont";
import { NavLink } from "react-router-dom";
import { NavDropdown, Nav, Badge } from "react-bootstrap";
import DropDownTitle from "../common/DropDownTitle";
import DataService from "../../services/DataService";
import { afterHour } from "../../services/DataFormatHandler";

import { toFullDate, afterATime } from "../../services/DataFormatHandler";
import { alertUpdation, alertError } from "../Alerts";
import moment from "moment";
class Orders extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.cartHandler = new DataService("carts");
		this.state = {
			itemsDisplayed: [],
			filter: 5,
			selectedRestaurant: this.cartHandler.getSelectedRestaurant(),
		};

		this.updateOrder = this.updateOrder.bind(this);
		this.reloadData();
	}
	/*
    fetch elements from server and reload data elements
    */
	reloadData = (filter) => {
		let inputParams = { days: 14 };
		if (this.state.selectedRestaurant != 0) {
			inputParams = {
				rest_id: this.state.selectedRestaurant,
				...inputParams,
			};
		}

		if (filter < 7) {
			inputParams["delivery_status"] = filter;
		}

		this.cartHandler.getOrderedCarts(inputParams).then((response) => {
			let currentData = response.data.data;
			console.log(currentData);
			this.setState({ itemsDisplayed: currentData });
		});
	};

	updateOrder = (e, orderId, inputParams) => {
		e.target.setAttribute("disabled", "disabled");

		//let inputParams = {delivery_status:deliveryStatus};

		this.cartHandler
			.updateData(inputParams, orderId)
			.then((response) => {
				if (response.data["status"] === "Sucess") {
					this.reloadData();
				} else {
					alertError(response.data["message"]);
				}
			})
			.catch((err) => alert(err));
	};
	render() {
		return (
			<>
				<div className="p-4 bg-white shadow-sm">
					<Nav
						activeKey={0}
						className="ml-auto"
						onSelect={this.closeMenu}
					>
						<Nav.Link
							eventKey={0}
							as={NavLink}
							activeclassname="active"
							exact
							to=""
						>
							<h4 className="font-weight-bold mt-0 mb-4">
								{" "}
								All Orders{" "}
							</h4>
							<span className="sr-only">(current)</span>
						</Nav.Link>

						<NavDropdown
							alignRight
							title={
								<DropDownTitle
									className="d-inline-block mt-2"
									image="img/filter.png"
									imageAlt="user"
									imageClass="nav-osahan-pic rounded-pill"
									title="Filter By "
								/>
							}
						>
							<NavDropdown.Item
								onClick={() => {
									this.reloadData();
								}}
								activeclassname="active"
								to="/myaccount/orders"
							>
								<Icofont icon="food-cart" /> Total
							</NavDropdown.Item>
							<NavDropdown.Item
								onClick={() => {
									this.reloadData(0);
								}}
								activeclassname="active"
								to="/myaccount/offers"
							>
								<Icofont icon="trash" /> Waiting
							</NavDropdown.Item>
							<NavDropdown.Item
								onClick={() => {
									this.reloadData(1);
								}}
								activeclassname="active"
								to="/myaccount/favourites"
							>
								<Icofont icon="ui-check" />
								Accepted & Ongoing
							</NavDropdown.Item>
							<NavDropdown.Item
								onClick={() => {
									this.reloadData(2);
								}}
								activeclassname="active"
								to="/myaccount/payments"
							>
								<Icofont icon="ui-clip-board" /> Accepted &
								Completed
							</NavDropdown.Item>
							<NavDropdown.Item
								onClick={() => {
									this.reloadData(3);
								}}
								activeclassname="active"
								to="/myaccount/addresses"
							>
								<Icofont icon="logout" /> Request for Delivery
							</NavDropdown.Item>

							<NavDropdown.Item
								onClick={() => {
									this.reloadData(4);
								}}
								activeclassname="active"
								to="/myaccount/addresses"
							>
								<Icofont icon="logout" /> Rejected
							</NavDropdown.Item>
							<NavDropdown.Item
								onClick={() => {
									this.reloadData(5);
								}}
								activeclassname="active"
								to="/myaccount/addresses"
							>
								<Icofont icon="logout" /> Picked Up
							</NavDropdown.Item>
							<NavDropdown.Item
								onClick={() => {
									this.reloadData(6);
								}}
								activeclassname="active"
								to="/myaccount/addresses"
							>
								<Icofont icon="logout" /> Delivered
							</NavDropdown.Item>
						</NavDropdown>
					</Nav>
					{this.state.itemsDisplayed.map((orderObj) => {
						let delivery_date =
							orderObj.ordered_date && orderObj.ordered_date != null
								? toFullDate(orderObj.ordered_date)
								: moment().format("dddd, MMMM Do YYYY, h:mm:ss a");

						console.log("Delivery ...", delivery_date);
						console.log(
							"Delivery ...",
							moment(
								delivery_date,
								"dddd, MMMM Do YYYY, h:mm:ss a"
							).fromNow()
						);

						return (
							<OrderCard
								orderObj={orderObj}
								image="/img/fast-food.png"
								imageAlt=""
								orderNumber={"order id :" + orderObj.order_id}
								info={moment(
									delivery_date,
									"dddd, MMMM Do YYYY, h:mm:ss a"
								).fromNow()}
								orderDate={afterATime(orderObj.ordered_date, 30)}
								pickUpDate={afterATime(orderObj.ordered_date, 45)}
								deliveredDate={delivery_date}
								orderTitle={
									orderObj.customer.first_name +
									" " +
									orderObj.customer.last_name
								}
								address={orderObj.customer.address}
								updateOrder={this.updateOrder}
								orderProducts={orderObj.added_items
									.sort((a, b) =>
										a.item_is_free === b.item_is_free
											? 0
											: a.item_is_free
											? 1
											: -1
									)
									.map((itemObj) => {
										return (
											<span
												style={{
													fontStyle: itemObj.item_is_free
														? "italic"
														: "normal",
												}}
											>
												{itemObj.item}
												{"("}
												{itemObj.quantity}
												{")"}
												{itemObj.item_is_free ? (
													<Badge
														pill
														variant={"secondary"}
													>
														Free
													</Badge>
												) : (
													""
												)}
												{" ,"}
											</span>
										);
									})}
								orderTotal={orderObj.total_amount}
								helpLink="#"
								backColor="#00FFFF"
								detailLink="/detail"
							/>
						);
					})}
				</div>
			</>
		);
	}
}
export default Orders;
